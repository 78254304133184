import * as React from "react";
// import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Page from "./../components/Layout/Page";
import Container from "./../components/Layout/Container";
import Seo from "../components/reusable/Seo";

const PrivacyPolicyPage = () => {
  return (
    <Page className="privacy-policy policy">
      <Seo
        title="Privacy Policy : Inviz AI"
        path="privacy-policy"
        description="This page is used to inform website visitors regarding our policies  with the collection, use, and disclosure of Personal Information if anyone decided to use our Service."
      />
      <div className="hero">
        <Container>
          <h1>
            <b>Privacy Policy</b>
          </h1>
        </Container>
      </div>
      <Container>
        <section>
          <p>
            InViz is a Delhi NCR head quartered company operating in the fields
            of technology, digital media, content and consulting.
          </p>
          <p>
            This page is used to inform website visitors regarding our policies
            with the collection, use, and disclosure of Personal Information if
            anyone decided to use our Service.
          </p>
          <p>
            If you choose to use our Service, then you agree to the collection
            and use of information in relation to this policy. The Personal
            Information that we collect is used for providing and improving our
            service. We will not use or share your information with anyone
            except as described in this Privacy Policy.
          </p>
          <p>
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible{" "}
            <Link to="/terms-conditions">here</Link>, unless otherwise defined
            in this Privacy Policy.
          </p>
        </section>
        <section>
          <h4>
            <strong className="green">Information Collection and Use</strong>
          </h4>
          <p>
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to Name, Age, Gender, Location etc. The
            information that we request will be retained by us and used as
            described in this privacy policy. This information is collected to
            provide more personalized and customized content recommendations and
            services.
          </p>
          <p>
            The website uses third-party services that may collect information
            used to identify you.
          </p>
          <p>
            Link to the privacy policy of third party service provider(s) used
            by the our website :
          </p>
          <ul>
            <li>
              <a
                href="https://marketingplatform.google.com/about/analytics/terms/us/"
                target="_blank"
                rel="noreferrer"
              >
                Google Analytics
              </a>
            </li>
          </ul>
        </section>
        <section>
          <h4>
            <strong className="green">Log Data</strong>
          </h4>
          <p>
            We want to inform you that whenever you use our Service, in a case
            of an error in the website we collect data and information (through
            third party products) on your device called Log Data. This Log Data
            may include information such as your device Internet Protocol (“IP”)
            address, device name, operating system version, the configuration of
            the app when utilizing our Service, the time and date of your use of
            the Service, and other statistics.
          </p>
        </section>
        <section>
          <h4>
            <strong className="green">Cookies</strong>
          </h4>
          <p>
            Cookies are files with small amount of data that is commonly used as
            an anonymous unique identifier. These are sent to your browser from
            the website that you visit and are stored on your device internal
            memory.
          </p>
          <p>
            This Service does not use these “cookies” explicitly. However, the
            website may use third party code and libraries that use “cookies” to
            collection information and to improve their services. You have the
            option to either accept or refuse these cookies and know when a
            cookie is being sent to your device. If you choose to refuse our
            cookies, you may not be able to use some portions of this Service.
          </p>
        </section>
        <section>
          <h4>
            <strong className="green">Service Providers</strong>
          </h4>
          <p>
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p>
            We want to inform users of this Service that these third parties
            have access to your Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose.
          </p>
        </section>
        <section>
          <h4>
            <strong className="green">Security</strong>
          </h4>
          <p>
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            will take all measures to safeguard your information and privacy,
            and undertaken corrective action in case of any breach.
          </p>
        </section>
        <section>
          <h4>
            <strong className="green">Links to Other Sites</strong>
          </h4>
          <p>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </p>
        </section>
        <section>
          <h4>
            <strong className="green">Children’s Privacy</strong>
          </h4>
          <p>
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to do necessary actions.
          </p>
        </section>
        <section>
          <h4>
            <strong className="green">Changes to This Privacy Policy</strong>
          </h4>
          <p>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page. These changes are effective immediately after they are posted
            on this page.
          </p>
        </section>
        <section>
          <h4>
            <strong className="green">Contact Us</strong>
          </h4>
          <p>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to{" "}
            <Link to="/contact-us">
              <b className="green">contact us</b>
            </Link>
            .
          </p>
        </section>
      </Container>
    </Page>
  );
};

export default PrivacyPolicyPage;
